<template>
    <card :to="to" v-bind="cardProps">
        <cdn-image :src="impression.source" :alt="impression.description ? impression.description : $t('label.impression')" :size="{width: 380, ratio: 4/2}" cover/>

        <div class="flex flex-column justify-center px-16 py-8 min-h-36 flex-grow-1">
            <span v-if="impression.description" class="truncate text-xs font-semi-bold leading-normal font-heading text-gray-500">{{ impression.description }}</span>
            <span class="leading-normal text-subtext-color" :class="impression.description ? 'text-2xs' : 'text-xs'">{{ $t('label.impression') }}</span>
        </div>

    </card>
</template>

<script>
import {CardMixin} from "@spoferan/nuxt-spoferan/mixins";

export default {
        name: 'EventImpressionCard',

        mixins: [CardMixin],

        props: {
            impression: {
                type: Object,
                required: true
            },

            to: {
                type: [String, Object],
                default: null
            }
        }
    }
</script>